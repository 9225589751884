<template>
  <div class="homebacknd">
    <div class="news-detail2">
      <div class="news-title2">
        <span :title="newsData.title">{{ newsData.title }}</span>
      </div>
      <div class="news-content">
        <div class="news-intro" v-html="newsData.content"></div>
      </div>
      <div class="news-time">
        <span>{{ newsData.wtime }}</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      newsData: [],
    };
  },
  mounted() {
    this.getNewsData();
  },
  activated() {
    this.getNewsData();
  },
  watch: {
    $route(to, from) {
      this.$router.go(0);
    },
  },
  methods: {
    async getNewsData() {
      await this.$axios
        .get("/news/GetNews", {
          params: {
            newsid: this.$route.params.str,
          },
        })
        // 将get到的接口数据赋给tableDataEdits】
        .then((response) => {
          this.newsData = { ...response.data };
        });
    },
  },
};
</script>

<style lang="less">
@media screen and (min-width: 800px) {
  .homebacknd {
    display: flex;
    background-color: #ffffff;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .news-detail2 {
    height: 100%;
    width: 80%;
    margin-top: 3%;
  }

  .news-time {
    margin-left: 70%;
    font-size: 20px;
    color: rgb(133, 132, 132);
    margin-bottom: 5%;
  }
  .news-title2 {
    font-size: 30px;
    font-family: Microsoft YaHei;
    padding-bottom: 15px;
    border-bottom: 1.5px solid rgb(110, 110, 110);
    width: 90%;
    // overflow: hidden;
    // white-space: nowrap;
    // text-overflow: ellipsis;
  }
  .news-intro {
    font-size: 20px;
  }
  .news-content {
    margin-top: 5%;
    margin-bottom: 5%;
    // margin-left: 5%;
  }
}
@media screen and (max-width: 700px) {
  .homebacknd {
    display: flex;
    background-color: #ffffff;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // background: linear-gradient(
    //     rgba(255, 255, 255, 0.8),
    //     rgba(173, 172, 172, 0.8)
    //   ),
    //   url("/assets/back.png") no-repeat;
    // background-size: 100% 100%;
  }
  .news-detail2 {
    height: 100%;
    width: 80%;
    margin-top: 3%;
  }

  .news-time {
    margin-left: 70%;
    font-size: 20px;
    color: rgb(133, 132, 132);
    margin-bottom: 5%;
  }
  .news-title2 {
    font-size: 25px;
    font-family: Microsoft YaHei;
    padding-bottom: 15px;
    border-bottom: 1.5px solid rgb(110, 110, 110);
    width: 100%;
    // overflow: hidden;
    // white-space: nowrap;
    // text-overflow: ellipsis;
  }
  .news-intro {
    font-size: 20px;
    // width: 350px;
  }
  .news-content {
    margin-top: 5%;
    margin-bottom: 5%;
    // margin-left: 5%;
    // width: 350px;
    font-size: 20px;
    height: 500px;
    text-align: justify;
    white-space: pre-wrap;
  }
}
</style>
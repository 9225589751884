<template>
  <div class="homebacknt">
    <div class="exec-pagent4">
      <div class="exec-dir">
        <div class="patTitle-frame">
          <div class="trends-title">
            <span>高价值专利转化</span>
          </div>
        </div>
        <el-card style="margin-bottom: 70px">
          <el-table
              :data="ConversionsData" style="width: 100%;" class="box-table"
              >
            <el-table-column prop="wtime" label="时间" min-width="20%" align="center">
            </el-table-column>
            <el-table-column prop="cname" label="高价值专利" min-width="80%">
              <template #default="scope">
                <div
                    :title="scope.row.cname"
                    @click="openDetails(scope.row.cid)"
                    style="
                    cursor: pointer;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    "
                >
                  {{ scope.row.cname }}
                </div>
              </template>
            </el-table-column>
          </el-table>
          <el-config-provider :locale="locale">
            <el-pagination
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="currentPage"
                :page-size="pageSize"
                layout="total, prev, pager, next, jumper"
                :total="total"
                style="margin-top: 30px; text-align: center"
            >
              <el-button
                  style="
                  border: 1px solid #b4bccc;
                  padding: 0px;
                  margin-left: 6px;
                "
                  size="mini"
                  @click="handleCurrentChange"
              >GO</el-button
              >
            </el-pagination>
          </el-config-provider>
        </el-card>
      </div>
    </div>
  </div>
</template>
<script>
import { ElConfigProvider } from "element-plus";
import zhCn from "element-plus/lib/locale/lang/zh-cn";
export default {
  components: {
    ElConfigProvider,
  },
  setup() {
    return {
      locale: zhCn,
    };
  },
  data() {
    return {
      total: 0,
      currentPage: 1,
      pageSize: 10,
      ConversionsData: [
        {
          wtime: '2016-05-02',
          cname: '专利名称1',
        },
        {
          wtime: '2016-05-02',
          cname: '专利名称2',
        },
        {
          wtime: '2016-05-02',
          cname: '专利名称2',
        },
        {
          wtime: '2016-05-02',
          cname: '专利名称3',
        },
        {
          wtime: '2016-05-02',
          cname: '专利名称4',
        },{
          wtime: '2016-05-02',
          cname: '专利名称4',
        },
      ],
    };
  },
  mounted() {
    this.getConversionsData();
  },
  activated() {
    this.getConversionsData();
  },
  watch: {
    $route(to, from) {
      this.$router.go(0);
    },
  },
  methods: {
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getConversionsData();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.currentPage = 1;
      this.getConversionsData();
    },
    async getConversionsData() {
      await this.$axios
          .get("/conversion/GetConversionAll", {
            params: {
              pagesize: this.pageSize,
              currentpage: this.currentPage,
            },
          })
          .then((response) => {
            if (response.data.status === "OK") {
              this.ConversionsData = response.data.data;
              this.total = response.data.total;
            }
          })
          .catch((e) => {
            console.log(e);
          });
    },
    openDetails(id) {
      this.$router.push({
        name: "highValuePatentsDetail",
        params: { str: id },
      });
    },
  },
};
</script>

<style lang="less">
* {
  margin: 0;
  padding: 0;
  //box-sizing: border-box;
}
.homebacknt {
  display: flex;
  // background-color: #f8f8f9;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
      rgba(255, 255, 255, 0.8),
      rgba(173, 172, 172, 0.8)
  ),
  url("/assets/back.png") no-repeat;
  background-size: 100% 100%;
}

.exec-pagent4 {
  display: flex;
  // background-color: #f8f8f9;
  flex-direction: column;
  justify-content: center;
  // margin-left: -25%;
  // margin-left: 5%;
  width: 85%;
  margin-top: 3%;
}
.patTitle-frame{
  /* 直线 1 */
  width: 700.01px;
  border-bottom: 1px solid rgb(98, 99, 101);
}
.trends-title {
  color: rgba(98, 99, 101, 0.92);
  font-family: Roboto;
  font-size: 48px;
  font-weight: 400;
  line-height: 56px;
  text-align: left;

  width: 440px;
  height: 70px;
  left: 38px;
  right: 1442px;
  top: 547px;
  bottom: 463px;

}
.el-card {
  width: 100%;
  margin-top: 5%;
  // margin-bottom: 10%;
}
</style>

<template>
  <el-menu
    :default-active="activeIndex2"
    class="el-menu-demo"
    mode="horizontal"
    @select="handleSelect"
    background-color="white"
    text-color="#000"
  >
    <el-menu-item index="1" class="neulogo"
      ><img id="icon2" src="@/static/logo.jpg" alt="" @click="gotoIndex" />
      <!-- <div class="title"> -->
      <strong class="chinese" @click="gotoIndex"
        >沈阳东大知识产权代理有限公司</strong
      >
      <!-- <span class="english"
        >SHENYANG DONGDA INTELLECTUAL PROPERTY AGENCY CO.,LTD</span
      > -->
      <!-- </div> -->
    </el-menu-item>
    <el-menu-item index="6" id="rightId5"
      ><font @click="gotoHighValPatConversion"
        ><strong>&nbsp;&nbsp;高价值专利转化</strong></font
      ></el-menu-item
    >
    <el-menu-item index="2" id="rightId1">
      <el-popover
        class="yincang"
        placement="bottom"
        width="100%"
        trigger="hover"
      >
        <template #reference>
          <font @click="gotoExecdir"
            ><strong>&nbsp;&nbsp;专利代理人</strong></font
          >
        </template>
        <div class="patent-bot">
          <img alt src="@/assets/patent.png" style="width: 18%; height: 10%" />
          <div class="patent-text" v-html="agentHead.introduction"></div>
          <div class="patent-but">
            <span class="pbutton" @click="gotoExecdir">执行董事</span>
            <span class="pbutton" @click="gotoExecdir">专业代理人</span>
          </div>
        </div>
      </el-popover>
    </el-menu-item>
    <el-menu-item index="3" id="rightId2"
      ><font @click="gotoNews"
        ><strong>&nbsp;&nbsp;新闻动态</strong></font
      ></el-menu-item
    >
    <el-menu-item index="4" id="rightId3"
      ><font @click="gettoLegal"
        ><strong>&nbsp;&nbsp;法律支持</strong></font
      ></el-menu-item
    >
    <el-menu-item index="5" id="rightId4">
      <el-popover
        placement="bottom"
        width="100%"
        trigger="hover"
        class="yincang"
      >
        <template #reference>
          <font @click="gotoMore"><strong>&nbsp;&nbsp;联系方式</strong></font>
        </template>
        <div class="patent-bot">
          <img alt src="@/assets/map1.png" style="width: 18%; height: 10%" />
          <span class="map-text">{{ mapHead.address }}</span>
          <div class="map-buts">
            <div class="map-but">
              <span class="mbut">网址: www.ddpta.com.cn</span>
              <span class="mbut">邮箱: {{ mapHead.email }}</span>
            </div>
            <div class="map-but">
              <span class="mpbut">电话:{{ mapHead.phone }}</span>
              <span class="mmbut" @click="gotoMore">更多信息</span>
            </div>
          </div>
        </div>
      </el-popover>
    </el-menu-item>
  </el-menu>
  <div class="pic-top">
    <el-carousel indicator-position="outside" v-if="indexPic.length > 0">
      <el-carousel-item v-for="item in indexPic" :key="item.picurl">
        <img
          :src="item.picurl"
          alt=""
          style="object-fit: fill; height: 100%; width: 100%"
        />
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
export default {
  name: "demo",
  data() {
    // this.
    return {
      studentID: document.cookie.split(";")[0].split("=")[1],
      activeIndex: "1",
      activeIndex2: "1",
      value: "Main",
      indexPic: [],
      total: 0,
      agentHead: [],
      mapHead: [],
    };
  },
  mounted() {
    this.getIndexPic();
    this.getStuffData();
    this.getMapData();
  },
  activated() {
    this.getIndexPic();
    this.getStuffData();
    this.getMapData();
  },
  watch: {
    $route(to, from) {
      this.$router.go(0);
    },
  },
  methods: {
    handleSelect(key, keyPath) {
      this.value = key;
    },
    //高价值专利转化
    gotoHighValPatConversion(){
      this.$router.push("/layout/highValPatConversion")
    },
    //执行董事/专利代理人
    gotoExecdir() {
      this.$router.push("/layout/execDir");
    },
    //更多联系方式
    gotoMore() {
      this.$router.push("/layout/moreContact");
    },
    //法律支持
    gettoLegal() {
      this.$router.push("/layout/legalSup");
    },
    //新闻动态
    gotoNews() {
      this.$router.push("/layout/newsTrends");
    },
    //返回首页
    gotoIndex() {
      this.$router.push("/");
    },
    //获取首页图片
    async getIndexPic() {
      await this.$axios
        .get("/pics/GetHomepic", {
          params: {
            pagesize: 3,
            currentpage: 1,
          },
        })
        .then((response) => {
          if (response.data.status === "OK") {
            this.indexPic = response.data.data;
            this.total = response.data.total;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    //获取Head中专利代理人展示的文字
    async getStuffData() {
      await this.$axios
        .get("/stuffs/GetStuff", {})
        // 将get到的接口数据赋给tableDataEdits】
        .then((response) => {
          this.agentHead = { ...response.data };
        });
    },
    //获取Head中联系方式展示的文字
    async getMapData() {
      await this.$axios
        .get("/contacts/GetContactTan", {})
        // 将get到的接口数据赋给tableDataEdits】
        .then((response) => {
          this.mapHead = { ...response.data };
        });
    },
  },
};
</script>

<style scpoed>
@media screen and (min-width: 800px) {
  .neulogo {
    font-size: 20px;
  }
  .pic-top {
    width: 100%;
    height: 390px;
    margin: auto;
  }
  .el-carousel__item h3 {
    color: #475669;
    font-size: 18px;
    opacity: 0.75;
    line-height: 400px;
    margin: 0;
  }

  .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  }

  .el-carousel__item:nth-child(2n + 1) {
    background-color: #d3dce6;
  }
  .el-carousel__container {
    height: 380px;
  }
  #icon2 {
    height: 30px;
    width: 50px;
    margin-right: 5px;
    margin-left: -5px;
  }
  .el-menu-item is-active {
  }

  .el-menu-demo #rightId1 {
    position: absolute;
    right: 360px;
    font-size: 18px;
  }
  .el-menu-demo #rightId2 {
    position: absolute;
    right: 240px;
    font-size: 18px;
  }
  .el-menu-demo #rightId3 {
    position: absolute;
    right: 120px;
    font-size: 18px;
  }
  .el-menu-demo #rightId4 {
    position: absolute;
    right: 0px;
    font-size: 18px;
  }
  .el-menu-demo #rightId5 {
    position: absolute;
    right: 500px;
    font-size: 18px;
  }
  .el-menu--collapse .el-menu .el-submenu,
  .el-menu--popup {
    min-width: 100px !important;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .patent-bot {
    display: flex;
    flex-direction: row;
    justify-content: left;
  }
  .patent-but {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 2%;
  }
  .pbutton {
    font-size: 20px;
    color: black;
    padding-bottom: 15px;
    border-bottom: 1.5px solid #000;
    cursor: pointer;
    margin-top: 10%;
    width: 150%;
  }
  .pbutton:hover {
    color: rgb(0, 115, 255);
  }
  .map-buts {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: -2%;
  }
  .map-but {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: -30%;
  }
  .mbut {
    font-size: 15px;
    color: black;
    padding-bottom: 15px;
    border-bottom: 1.5px solid #000;
    margin-top: 10%;
    width: 130%;
    margin-left: 50%;
  }
  .mpbut {
    font-size: 15px;
    color: black;
    padding-bottom: 15px;
    border-bottom: 1.5px solid #000;
    margin-top: 6%;
    width: 100%;
    margin-left: 110%;
  }
  .mmbut {
    font-size: 15px;
    color: black;
    padding-bottom: 15px;
    border-bottom: 1.5px solid #000;
    cursor: pointer;
    margin-top: 5%;
    width: 100%;
    margin-left: 110%;
    color: rgb(0, 115, 255);
  }

  .patent-text {
    width: 60%;
    margin-top: 1%;
    margin-left: 2%;
    font-size: 18px;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  }
  .map-text {
    width: 30%;
    margin-top: 5%;
    margin-left: 5%;
    font-size: 18px;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  }
}
@media screen and (max-width: 700px) {
  .chinese {
    display: none;
  }
  .neulogo {
    font-size: 20px;
  }
  .pic-top {
    width: 100%;
    height: 390px;
    margin: auto;
  }
  .el-carousel__item h3 {
    color: #475669;
    font-size: 18px;
    opacity: 0.75;
    line-height: 400px;
    margin: 0;
  }

  .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  }

  .el-carousel__item:nth-child(2n + 1) {
    background-color: #d3dce6;
  }
  .el-carousel__container {
    height: 380px;
  }
  #icon2 {
    height: 25px;
    width: 40px;
    /* margin-right: 5px; */
    margin-left: -20px;
  }
  .el-menu-item is-active {
  }

  .el-menu-demo #rightId1 {
    position: absolute;
    right: 315px;
    font-size: 18px;
    max-width: 150px;
    float: left;
  }
  .el-menu-demo #rightId2 {
    position: absolute;
    right: 210px;
    font-size: 18px;
  }
  .el-menu-demo #rightId3 {
    position: absolute;
    right: 100px;
    font-size: 18px;
  }
  .el-menu-demo #rightId4 {
    position: absolute;
    right: -7px;
    font-size: 18px;
  }
  .el-menu-demo #rightId5 {
    position: absolute;
    right: 420px;
    font-size: 18px;
  }
  .el-menu--collapse .el-menu .el-submenu,
  .el-menu--popup {
    min-width: 50px !important;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .patent-bot {
    display: flex;
    flex-direction: row;
    justify-content: left;
    display: none;
  }
  .patent-but {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 2%;
  }
  .pbutton {
    font-size: 20px;
    color: black;
    padding-bottom: 15px;
    border-bottom: 1.5px solid #000;
    cursor: pointer;
    margin-top: 10%;
    width: 150%;
  }
  .pbutton:hover {
    color: rgb(0, 115, 255);
  }
  .map-buts {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: -2%;
  }
  .map-but {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: -30%;
  }
  .mbut {
    font-size: 15px;
    color: black;
    padding-bottom: 15px;
    border-bottom: 1.5px solid #000;
    margin-top: 10%;
    width: 130%;
    margin-left: 50%;
  }
  .mpbut {
    font-size: 15px;
    color: black;
    padding-bottom: 15px;
    border-bottom: 1.5px solid #000;
    margin-top: 6%;
    width: 100%;
    margin-left: 110%;
  }
  .mmbut {
    font-size: 15px;
    color: black;
    padding-bottom: 15px;
    border-bottom: 1.5px solid #000;
    cursor: pointer;
    margin-top: 5%;
    width: 100%;
    margin-left: 110%;
    color: rgb(0, 115, 255);
  }

  .patent-text {
    width: 60%;
    margin-top: 1%;
    margin-left: 2%;
    font-size: 18px;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  }
  .map-text {
    width: 30%;
    margin-top: 5%;
    margin-left: 5%;
    font-size: 18px;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  }
  .yincang {
    display: none;
  }
}
</style>

